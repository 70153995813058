.text {
  font-size: 1.5em;
  text-align: center;
  color: #4288f0;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  padding: 1.5rem;
  color: gray;
}
.subtitle {
  font-size: 1.5em;
  text-align: center;
  padding: 1.5rem;
  color: gray;
}
.wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
