.invoice-viewer {
  h5 {
    font-size: 15px;
  }
  .viewer__order-info {
  }
  .viewer__billing-info {
  }
}

// Media print
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    margin: 0 0 0 -20 !important;
  }

  @page {
    size: A4; /* DIN A4 standard, Europe */
  }

  #summaryReport {
    visibility: visible;
    float: none;
  }

  // .scaryText{
  //   margin-top: 50px !important;
  // }

  .overViewSection {
    margin-top: 0px !important;
  }

  .smallChartSection {
    margin-top: 0px !important;
  }

  .scannedDataSection {
    margin-top: 50px !important;
  }

  .summaryText {
    margin-top: 9% !important;
  }

  .breachIdentification {
    margin-top: -270px !important;
  }

  .attackVisibilty {
    margin-top: 200px !important;
  }

  .threatOrgCard {
    margin-top: 700px !important;
  }

  .portAndServices {
    margin-top: 10px !important;
  }
  .cloudPosture-card {
    margin-top: 50px;
  }

  .summarySectionBar {
    margin-top: 15% !important;
  }

  .summarySection {
    margin-left: 12px;
  }

  .cardBorder {
    border: 1px solid #ececec;
  }

  .tlsSslSecurity {
    margin-top: 0px !important;
  }

  .certificateSecurity {
    margin-top: 0px !important;
  }

  .securityConfigurationSection {
    margin-top: 150px !important;
  }

  .securityConfiguration {
    margin-top: 0px !important;
  }

  .userScanInfo {
    margin-top: 200px !important;
  }

  .report-background {
    background-color: white !important;
  }

  .footer {
    // margin-top: 460px !important;
    padding-bottom: 220px !important;
    visibility: visible !important;
  }

  .padding-0 {
    padding: 0 !important;
  }

  .padding-left-12 {
    padding-left: 12px !important;
  }

  .padding-right-8 {
    padding-right: 8px !important;
  }

  .position-left-0 {
    left: 0px !important;
  }

  .position-unset {
    position: unset !important;
  }

  .max-width-1 {
    max-width: 1%;
  }

  .max-width-75 {
    max-width: 75% !important;
  }

  .height-0 {
    height: 0 !important;
  }

  .width-100 {
    width: 100% !important;
  }

  .hideOnReport {
    visibility: hidden;
  }

  .hide {
    display: none !important;
  }

  .visibilityHidden {
    visibility: visible !important;
  }

  .breakBefore {
    display: block;
    page-break-after: always;
    position: relative;
  }

  .break {
    display: block;
    page-break-after: always;
    position: relative;
  }

  .thead {
    background: red !important;
    -webkit-print-color-adjust: exact;
  }

  .table th {
    background-color: #5b9bd5 !important;
  }

  .highRisk th {
    background-color: #d6443a !important;
  }

  .mediumRisk th {
    background-color: #ed7c31 !important;
  }

  .report-width {
    width: 636px !important;
  }

  .padding-5 {
    padding: 5px !important;
  }

  .font-2 {
    font-size: calc(6px + 0.5vw) !important;
  }

  .font-1 {
    font-size: calc(6px + 0.1vw) !important;
  }

  .font-12 {
    font-size: calc(6px + 0.4vw) !important;
  }

  .font-16 {
    font-size: calc(6px + 0.6vw) !important;
  }

  .font-20 {
    font-size: calc(6px + 0.9vw) !important;
  }

  .font-24 {
    font-size: 24px !important;
  }

  .top-26 {
    top: 26% !important;
  }

  .right-26 {
    right: 26% !important;
  }

  .top-56 {
    top: 56% !important;
  }

  .right-19 {
    right: 19% !important;
  }
  footer {
    page-break-after: always;
  }
}
