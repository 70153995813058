.sidenav,
.topbar,
.content-wrap {
  transition: all 0.3s ease;
}

.sidenav .scrollable {
  width: $sidenav-width;
}

.layout1 {
  height: 100%;
  .content-wrap {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    height: 100%;
    .topbar {
      top: 0;
      z-index: 96;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.95) 44%,
        rgba(247, 247, 247, 0.4) 50%,
        rgba(255, 255, 255, 0)
      );
      .topbar-hold {
        position: relative;
        box-shadow: $elevation-z8;
        height: $topbar-height;
        border-radius: inherit;
        padding: 0 1rem;
        // z-index: 15;
      }
    }
    #scrollable-content-1 {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      .content {
        position: relative;
      }
    }
  }

  // Fixed Topbar

  // sidenav full
  &.sidenav-full {
    .sidenav {
      // width: $sidenav-width;
      left: 0;
      .sidenav__toggle {
        // display: block;
      }
    }
  }

  // sidenav compact
  &.sidenav-compact {
    .sidenav {
      width: $sidenav-compact-width;
      overflow: hidden;
      .brand-area {
        .brand__text {
          display: none;
        }
      }
      .scrollable {
        .sidenav__user {
          .username-photo {
            align-items: flex-start;
          }
          img {
            height: 1.8rem;
            width: 1.8rem;
          }
          .username,
          .user__menu {
            display: none;
          }
        }
        .navigation {
          .nav-item {
            padding: 0;
            width: 48px;
            overflow: hidden;
            justify-content: center;
            margin-left: 6px;
            border-radius: 2px;
            .item-text,
            .item-arrow,
            .badge {
              display: none;
            }
            > button {
              justify-content: center;
            }
          }
        }
      }
      // COMPACT HOVER
      // &:hover {
      //   width: $sidenav-width;
      //   .brand-area {
      //     .brand__text {
      //       display: inline-block;
      //     }
      //   }
      //   .scrollable {
      //     width: $sidenav-width;
      //     .sidenav__user {
      //       .username-photo {
      //         align-items: center;
      //       }
      //       img {
      //         height: 4rem;
      //         width: 4rem;
      //       }
      //       .username,
      //       .user__menu {
      //         display: flex;
      //       }
      //     }
      //     .navigation {
      //       button.nav-item {
      //         padding: 0 20px;
      //       }
      //       .nav-item {
      //         // padding: 0 20px;
      //         width: 100%;
      //         justify-content: space-between;
      //         margin-left: 0;
      //         border-radius: 0;
      //         .item-text,
      //         .item-arrow,
      //         .badge {
      //           display: inline-block;
      //         }
      //         > button {
      //           justify-content: flex-start;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  // closed sidenav
  &.sidenav-close {
    .sidenav {
      // width: 0px;
      left: -#{$sidenav-width};
    }
    .content-wrap {
      width: 100%;
      vertical-align: top;
      margin-left: 0px;
      .topbar {
        width: 100%;
      }
      .content {
      }
    }
  }

  // mobile sidenav
  &.sidenav-mobile {
    .sidenav {
      width: $sidenav-width;
    }

    .content-wrap {
      width: 100%;
      vertical-align: top;
      margin-left: 0px;
      .topbar {
        width: 100%;
      }
    }
  }
}
