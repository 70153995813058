.attackSectionWidth {
  margin-top: 20px;
  overflow: hidden;
  width: 680px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 400px) and (max-width: 850px) {
  .attackSectionWidth {
  }
}
