.flex {
  display: flex;
}
.f-row {
  flex-direction: row;
}
.f-row-reverse {
  flex-direction: row-reverse;
}
.f-col {
  flex-direction: column;
}

.f-justify-f-start {
  justify-content: flex-start;
}
.f-justify-stretch {
  justify-content: stretch;
}
.f-justify-evenly {
  justify-content: space-evenly;
}
.f-justify-around {
  justify-content: space-around;
}
.f-justify-between {
  justify-content: space-between;
}
.f-justify-f-end {
  justify-content: flex-end;
}
.f-justify-start {
  justify-content: start;
}
.f-justify-center {
  justify-content: center;
}
.f-justify-end {
  justify-content: end;
}

.f-align-f-start {
  align-items: flex-start;
}
.f-align-stretch {
  align-items: stretch;
}
.f-align-evenly {
  align-items: space-evenly;
}
.f-align-around {
  align-items: space-around;
}
.f-align-between {
  align-items: space-between;
}
.f-align-f-end {
  align-items: flex-end;
}
.f-align-start {
  align-items: start;
}
.f-align-center {
  align-items: center;
}
.f-align-end {
  align-items: end;
}

.f-grow-1 {
  flex-grow: 1;
}
.f-grow-2 {
  flex-grow: 2;
}
.f-grow-3 {
  flex-grow: 3;
}
.f-grow-4 {
  flex-grow: 4;
}
.f-grow-5 {
  flex-grow: 5;
}
.f-grow-6 {
  flex-grow: 6;
}
.f-grow-7 {
  flex-grow: 7;
}
.f-grow-8 {
  flex-grow: 8;
}
.f-grow-9 {
  flex-grow: 9;
}
.f-grow-10 {
  flex-grow: 10;
}
.f-grow-11 {
  flex-grow: 11;
}
.f-grow-12 {
  flex-grow: 12;
}

.f-width-1 {
  width: 8.333333%;
}

.f-width-2 {
  width: 16.666667%;
}

.f-width-3 {
  width: 25%;
}
.f-width-4 {
  width: 33.333333%;
}

.f-width-5 {
  width: 41.666667%;
}

.f-width-6 {
  width: 50%;
}

.f-width-7 {
  width: 58.333333%;
}

.f-width-8 {
  width: 66.666667%;
}

.f-width-9 {
  width: 75%;
}

.f-width-10 {
  width: 83.333333%;
}

.f-width-11 {
  width: 91.666667%;
}

.f-width-12 {
  width: 100%;
}
