.input_width {
  width: 330px;
}

.wrapper {
  position: "relative";
}

.country_code {
  width: 80px;
}
.country_mobile_number {
  width: 240px;
}
