.layout2 {
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  height: 100%;
  transition: all 0.15s ease;
  #scrollable-content-2 {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
    overflow-y: auto;
  }
  &.sidenav-close {
    .sidenav {
      // width: 0px;
      left: -#{$sidenav-width};
    }
  }
}
